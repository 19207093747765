import React, { useState } from "react";
import { FaImages } from "react-icons/fa";

function ResultDisplay({ result }) {
  const { pixelDifference, screenshot1, screenshot2, diffImage } = result;

  // State to track zoom details (mouse position and zoom state)
  const [zoomDetails, setZoomDetails] = useState({ x: 0, y: 0, zoom: false });

  // Handle mouse movement to update zoom position
  const handleMouseMove = (e) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const { offsetWidth, offsetHeight } = target;
    const x = (offsetX / offsetWidth) * 100;
    const y = (offsetY / offsetHeight) * 100;
    setZoomDetails({ x, y, zoom: true });
  };

  // Handle mouse leaving the image (reset zoom)
  const handleMouseLeave = () => {
    setZoomDetails({ zoom: false });
  };

  // Zoomable Image component with reduced size
  const ZoomableImage = ({ src, alt }) => (
    <div
      className="relative overflow-hidden rounded-lg cursor-pointer group"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={src}
        alt={alt}
        className={`w-2/3 h-auto object-contain mx-auto transition-transform duration-300 ease-in-out ${
          zoomDetails.zoom ? 'transform scale-150' : ''
        }`}
        style={{
          transformOrigin: `${zoomDetails.x}% ${zoomDetails.y}%`,
        }}
      />
    </div>
  );

  // Professional and modern color palette
  const colors = {
    bgPrimary: "bg-white",
    cardBg: "bg-gray-50",
    highlightText: "text-blue-500",
    cardShadow: "shadow-lg",
    textMain: "text-gray-900",
    borderPrimary: "border-gray-300",
    subtleText: "text-gray-600",
    gradient: "bg-gradient-to-r from-green-400 to-blue-500",
    error: "text-red-600",
  };

  return (
    <div className={`mt-8 p-10 rounded-lg max-w-7xl mx-auto ${colors.bgPrimary} shadow-2xl`}>
      
      {/* Result Summary with Pixel Difference */}
      <div className={`flex items-center justify-between p-6 rounded-lg border-l-4 ${pixelDifference === 0 ? 'border-green-500 bg-green-50' : 'border-red-500 bg-red-50'} mb-8 shadow-md`}>
        <div className="flex items-center">
          <FaImages className={`text-2xl mr-3 ${pixelDifference === 0 ? 'text-green-500' : 'text-red-500'}`} />
          <p className={`text-xl font-semibold ${colors.subtleText}`}>
            {pixelDifference === 0 ? 'Screenshots are identical' : 'Screenshots are different'}
          </p>
        </div>
        <p className={`text-4xl font-bold ${colors.gradient} text-white px-6 py-2 rounded-lg shadow-lg`}>
          Pixel Difference: {pixelDifference}
        </p>
      </div>

      {/* Grid layout for images: 3 images in the same row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        
        {/* Screenshot 1 */}
        <div className={`rounded-lg border ${colors.cardBg} ${colors.cardShadow} p-4`}>
          <p className={`mb-2 text-lg font-medium ${colors.textMain}`}>URL 1 Screenshot</p>
          <ZoomableImage src={screenshot1} alt="Screenshot 1" />
        </div>

        {/* Screenshot 2 */}
        <div className={`rounded-lg border ${colors.cardBg} ${colors.cardShadow} p-4`}>
          <p className={`mb-2 text-lg font-medium ${colors.textMain}`}>URL 2 Screenshot</p>
          <ZoomableImage src={screenshot2} alt="Screenshot 2" />
        </div>

        {/* Diff Image */}
        <div className={`rounded-lg border ${colors.cardBg} ${colors.cardShadow} p-4`}>
          <p className={`mb-2 text-lg font-medium ${colors.textMain}`}>Diff Image</p>
          <ZoomableImage src={diffImage} alt="Diff Image" />
        </div>
      </div>
    </div>
  );
}

export default ResultDisplay;
