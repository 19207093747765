import React, { useState } from "react";
import Header from "./components/Header";
import Form from "./components/Form";
import ResultDisplay from "./components/ResultDisplay";

function App() {
  const [result, setResult] = useState(null);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">
      <Header />
      <Form setResult={setResult} />
      {result && <ResultDisplay result={result} />}
    </div>
  );
}

export default App;

